import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImgViewerComponent } from './img-viewer.component';

@NgModule({
  declarations: [ImgViewerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    PdfViewerModule
  ]
})
export class ImgViewerModule { }
