import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatButtonToggleModule, MatCardModule, MatDialogModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatRadioModule, MatToolbarModule
} from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { ModalWarningComponent } from '../modal-warning/modal-warning.component';
import { ModalWarningModule } from '../modal-warning/modal-warning.module';
import { LoginComponent } from './login.component';

const routes: Routes = [
    { path: '', component: LoginComponent },
];
@NgModule({
    imports: [
        MatCardModule,
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        MatIconModule,
        MatRadioModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        ModalWarningModule
    ],
    declarations: [
        LoginComponent,
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ],
    entryComponents: [
        ModalWarningComponent
    ]
})
export class LoginModule {
}
