import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  monthTextMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  }


  agencyTransformation = [
    {
      id: "CBA",
      value: 'Chubb Business Advisor',
    },
    {
      id: "CBP",
      value: 'Chubb Business Partner',
  
    }
  
  ];



  typeMap = new Map([
    ['UL', 'Unit Link'],
    ['CL', 'Credit Life']
  ]);

  religionList = [
    'Islam',
    'Kristen',
    'Katolik',
    'Budha',
    'Hindu',
    'Lainnya'
  ];
  statusList = [
    'Belum Menikah',
    'Sudah Menikah',
    'Duda / Janda'
  ];
  statusListNew = [
    {
      id: 0,
      value: 'Belum Menikah',
    },
    {
      id: 1,
      value: 'Sudah Menikah',

    },
    {
      id: 2,
      value: 'Duda / Janda',
    }

  ];
  educationList = [
    'SLTA',
    'D1',
    'D2',
    'D3',
    'D4',
    'S1',
    'S2',
    'S3'
  ];

  regionList = [
    'REGION 1 - BEKASI',
    'REGION 1 - JAKARTA 1',
    'REGION 1 - JAKARTA 2',
    'REGION 1 - JAKARTA 3',
    'REGION 1 - LAMPUNG',
    'REGION 1 - MEDAN 1',
    'REGION 1 - MEDAN 2',
    'REGION 1 - PADANG',
    'REGION 1 - PONTIANAK',
    'REGION 1 - TANGERANG',
    'REGION 2 - BALI 1',
    'REGION 2 - BALI 2',
    'REGION 2 - BANDUNG',
    'REGION 2 - GAO BALI',
    'REGION 2 - GAO JEMBER',
    'REGION 2 - GAO PALU',
    'REGION 2 - KEDIRI',
    'REGION 2 - MAKASSAR',
    'REGION 2 - MALANG',
    'REGION 2 - SEMARANG',
    'REGION 2 - SURABAYA 1',
    'REGION 2 - SURABAYA 2',
    'REGION 2 - GAO SURABAYA'
  ];

  genderList = [
    'Pria',
    'Wanita'
  ];
  hasilPenilaianList = [
    'Memiliki pengalaman sebagai penjual dan berprestasi',
    'Memiliki networking yang dapat diandalkan',
    'Mobilitas tinggi karena memiliki mobil sendiri',
    ''
  ];
  checkListTopic = [
    'Company Profile',
    'Kompensasi',
    'Jenjang Karir dan Reward',
    'Support Company : Training,Portal,eSMART,PAS-12,(MDP,IPP dan Super TAP)*',
    'Harapan Pewawancara terhadap kandidat',
    'Langkah Awal: Bank Nama, Regristrasi AAJI,PAS-12,Kelengkapan Dokumen'
  ];

  //leader select optin list
  homeStatList = ["Pribadi", "Keluarga", "Sewa / Kontrak"];
  opVehicleList = ["Ada", "Tidak Ada"];
  vehicleStatList = ["Pribadi", "Keluarga", "Dinas Perusahaan"];
  questionList = [
    "1. Apakah KESUKSESAN terbesar yang pernah Anda rasakan / dapatkan ? Ceritakan APA, BAGAIMANA, KAPAN dan DIMANA ?",
    "2. Apakah KEGAGALAN terbesar yang pernah Anda rasakan / dapatkan ? Ceritakan APA, BAGAIMANA, KAPAN dan DIMANA ?",
    "3. Dalam hidup pastinya Anda memiliki kejadian / keadaan tertentu yang membuat Anda merasa patut dibanggakan ? Ceritakan APA, BAGAIMANA dan DIMANA ?",
    "4. Jika Anda diminta untuk menceritakan tentang KARAKTER DIRI ANDA, Apa kata orang tentang diri anda ? misalnya:kompetitif, koordinatif, kooperatif, toleransi dll",
    "5. Apakah pekerjaan yang PALING ANDA SUKAI ? Ceritakan Alasannya ?",
    "6. Apakah pekerjaan yang PALING TIDAK ANDA SUKAI ? Ceritakan Alasannya ?",
    "7. Apakah HARAPAN ANDA (Penghasilan, Jabatan, dsb) jika Anda bergabung bersama PT Chubb Life Insurance Indonesia ? Ceritakan ?",
  ]
  positionList = [{ id: "Agent", value: "Agent" }, { id: "AM", value: "Agent Manager" }, { id: "SAM", value: "Senior Agent Manager" }, { id: "RAM", value: "Regional Agent Manager" }
    , {
      id: "CBA",
      value: 'Chubb Business Advisor',
    },
    {
      id: "CBP",
      value: 'Chubb Business Partner',
  
    }
  ];

  // efpk select option list
  yesNoList = [{ id: 1, value: 'Ya' }, { id: 0, value: 'Tidak' }]
  npwpStatusList = [{ id: 1, value: 'Ada' }, { id: 0, value: 'Tidak Ada' }];
  isAgentList = [{ id: 1, value: 'Agent' }, { id: 0, value: 'Bukan Agent' }];
  employmentTypeList = ['Full Time', 'Part Time'];
  citizenshipList = ['Indonesia', 'Asing'];
  ptpkStatusList = [
    { id: "TK/0", value: "Tidak kawin & tidak memiliki tanggungan" },
    { id: "TK/1", value: "Tidak kawin & memiliki 1 tanggungan" },
    { id: "TK/2", value: "Tidak kawin & memiliki 2 tanggungan" },
    { id: "TK/3", value: "Tidak kawin & memiliki 3 tanggungan" },
    { id: "K/0", value: "Kawin & tidak memiliki tanggungan" },
    { id: "K/1", value: "Kawin & memiliki 1 tanggungan" },
    { id: "K/2", value: "Kawin & memiliki 2 tanggungan" },
    { id: "K/3", value: "Kawin & memiliki 3 tanggungan" },
    { id: "K/I/0", value: "Kawin / Istri Bekerja & tidak ada tanggungan" },
    { id: "K/I/1", value: "Kawin / Istri Bekerja & memiliki 1 tanggungan" },
    { id: "K/I/2", value: "Kawin / Istri Bekerja & memiliki 2 tanggungan" },
    { id: "K/I/3", value: "Kawin / Istri Bekerja & memiliki 3 tanggungan" }
  ];

  ptpkStatusListView = [
    { id: "TK/0", value: "TK" },
    { id: "TK/1", value: "TK" },
    { id: "TK/2", value: "TK" },
    { id: "TK/3", value: "TK" },
    { id: "K/0", value: "K0" },
    { id: "K/1", value: "K1" },
    { id: "K/2", value: "K2" },
    { id: "K/3", value:  "K3" },
    { id: "K/I/0", value: "K0" },
    { id: "K/I/1", value: "K1" },
    { id: "K/I/2", value: "K2" },
    { id: "K/I/3", value: "K3" }
  ];

  pernytaanStatList = [
    "Saya tidak mempunyai penghasilan lain diluar penghasilan saya dari PT Chubb Life Assurance",
    "Saya mempunyai penghasilan lain diluar penghasilan saya dari PT Chubb Life"
  ]

  //uploads
  docType = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/msword': 'doc',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/tiff': 'tiff',
    'video/mp4': 'mp4'
  }
  blobType = {
    'pdf': 'application/pdf',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'xls': 'application/vnd.ms-excel',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'doc': 'application/msword',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'mp4': 'video/mp4'
  }
  srcFileMap = {
    'jpeg': '',
    'png': '',
    'xls': './assets/msexcel-icon.png',
    'xlsx': './assets/msexcel-icon.png',
    'doc': './assets/msword-icon.png',
    'docx': './assets/msword-icon.png',
    'pdf': './assets/pdf-logo.png',
  }

  periviewableDocList = ['jpeg', 'jpg', 'png', 'pdf']

  //efpkDocs
  assetFiles = {
    'antiTwist': '/assets/efpk-anti-twisting/ANTI_TWISTING.pdf',
    'kontrak': '/assets/efpk-kontrak/Cover_of_Agency_Agreement.pdf',
    'kontrak-agent': '/assets/efpk-agent-contract/kontrak-agent.pdf',
    'kontrak-am': '/assets/efpk-agent-contract/kontrak-leader.pdf',
    'kontrak-sam': '/assets/efpk-agent-contract/kontrak-leader.pdf',
    'kontrak-ram': '/assets/efpk-agent-contract/kontrak-leader.pdf',
  }
  assetsDocMapPreview = {}

  assetsNameMapPreview = {
    'antiTwist': 'ANTI_TWISTING.pdf',
    'kontrak': 'Cover_of_Agency_Agreement.pdf',
    'kontrak-agent': 'Kontrak Agent',
    'kontrak-am': 'Kontrak AM',
    'kontrak-sam': 'Kontrak SAM',
    'kontrak-ram': 'Kontrak RAM',
  }

  efpkDocLabel = {
    'documentKtp': 'KTP',
    'documentNpwp': 'NPWP',
    'documentKartuKeluarga': 'Kartu Keluarga',
    'documentBankAccount': 'Rekening Bank',
    'documentAAJI': 'E-Certificate AAJI'
  };

  //Error Message
  requiredMsg = 'can not be empty, please complete it!'

  noDataToExportMsg: string = 'Sorry, No data to export';

  selectOptionValueOnBlank = ['', null, undefined]

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getToday() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  formatDate(date) {
    if (!date) return '';

    let dateSplit = date.split('-');
    return [dateSplit[2], dateSplit[1], dateSplit[0]].join('/');
  }

  formatDateDash(date) {
    if (!date) return '';

    let dateSplit = date.split('-');
    return [dateSplit[2], dateSplit[1], dateSplit[0]].join('-');
  }

  convertToExcelDate(date) {
    let dateSplit = date.split('/');
    return [dateSplit[0], this.monthTextMap[dateSplit[1]], dateSplit[2]].join('/');
  }

  convertToExcelDate2(date) {
    let dateSplit = date.split('/');
    return [dateSplit[0], this.monthTextMap[dateSplit[1]], dateSplit[2]].join(' ');
  }

  formatDateWithDash(date) {
    let dateSplit = date.split('/');
    return [dateSplit[2], dateSplit[1], dateSplit[0]].join('-');
  }

  formatLocaleDate(d: Date) {
    if (!d) return ""

    var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;


    return [day, month, year].join('/');
  }

  formatLocaleDate2(date) {
    let d = new Date(date)
    if (!d) return ""

    var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  formatPPAJInquiryDate(date) {
    console.log('before : ', date);

    if (!date) return ''

    return this.formatLocaleDate(new Date(date))
  }

  getFileNameDate(type) {
    let nameDate = this.getTodayDateTime() + '.' + type;
    return nameDate;
  }

  getTodayDateTime() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      yr = d.getFullYear(),
      hr = '' + d.getHours(),
      min = '' + d.getMinutes();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    let year = yr.toString().slice(2);

    return [day, month, year, hr, min].join('');
  }

  exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // save file
    XLSX.writeFile(workbook, this.getFileNameDate('xlsx'));
  }

  exportAsExcelFilePersistency(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // save file
    XLSX.writeFile(workbook, 'Persistency_Detail_13.xlsx');
  }




  // getRandomBase64(){

  //   return base64
  // }

  fixFileNameAgent(name, type) {
    const fileName = name.split('_')
    return [fileName[fileName.length - 2], fileName[fileName.length - 1]].join('_');
  }

  fixFileNameAgentEfpk(name, field) {
    let splitName = name.split('_');
    let length = splitName.length
    if (field == 'documentKtp') {
      return [splitName[length - 1].split('.')[0], splitName[length - 2]].join('_')
    } else {
      return [splitName[length - 2], splitName[length - 1].split('.')[0]].join('_')
    }

  }

  callErrorAlert() {
    alert('Something is wrong. please try again!')
  }

  createBlob(b64Data: any, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  convertDate(date: string) {
    if (!date) return ""

    const monthText = {
      '01': 'Januari',
      '02': 'Februari',
      '03': 'Maret',
      '04': 'April',
      '05': 'Mei',
      '06': 'Juni',
      '07': 'Juli',
      '08': 'Agustus',
      '09': 'September',
      '10': 'Oktober',
      '11': 'November',
      '12': 'Desember'
    }
    let d = date.split('-')

    return `${d[2]} ${monthText[d[1]]} ${d[0]}`
  }

  isEmpty(data) {
    return !data ? "-" : data;
  }

  isNull(data) {
    return data == null || data == undefined ? "" : data;
  }

  convertToNPWPFormat(npwp: string) {
    if (npwp.length > 15 && npwp[2] == '.' && npwp[6] == '.' && npwp[10] == '.' && npwp[16] == '.' && npwp[12] == '-') return npwp
    return `${npwp.substr(0, 2)}.${npwp.substr(2, 3)}.${npwp.substr(5, 3)}.${npwp[8]}-${npwp.substr(9, 3)}.${npwp.substr(12, 3)}`;
  }

  getDateYesterday(date: Date): Date {
    return new Date(date.getTime() - 24 * 60 * 60 * 1000);
  }

  getDateTomorrow(date: Date): Date {
    return new Date(date.getTime() + 24 * 60 * 60 * 1000);
  }

  getFullPath(dwnloadpath: string, filepath: string) {
    let formatfilePath = (filepath as any).replaceAll('\\', '/');
    let dlPath = dwnloadpath[0] == '\\' ? dwnloadpath.substr(2).replace('\\', '/') : dwnloadpath.substr(1)
    let fPath = formatfilePath[0] == '/' ? formatfilePath.substr(1) : formatfilePath
    return dlPath + fPath;
  }

  getFileNamePolicy(path: string) {
    let fileName = '';
    if (path) {
      if (path.includes('\\')) {
        let pth1 = path.split('\\')
        fileName = pth1[pth1.length - 1]
      } else if (path.includes('/')) {
        let pth2 = path.split('/')
        fileName = pth2[pth2.length - 1]
      }
    }

    return fileName;
  }

  npwpValidator(control: AbstractControl): { [key: string]: boolean } | null {
    return !control.value
      ?
      null
      :
      control.value.length < 15
        ?
        {
          format: true
        }
        : null
  }

  actvMonthValidator(control: AbstractControl): { [key: string]: boolean } | null {
    return !control.value
      ?
      null
      :
      +control.value > 12
        ?
        {
          max: true
        }
        : null
  }

  styleObjectOnTableProcessing(isProcessing: boolean): Object {
    if (isProcessing) {
      return {
        "pointer-events": "none",
        "cursor": "wait"
      }
    }
    return {}
  }

  formatCurrency(numbers: number): string {
    if (numbers == null || numbers == undefined) return ''

    const formattedNum = Intl.NumberFormat().format(numbers)
    return formattedNum.length > 3 ? formattedNum.replace(/,/g, '.') : formattedNum
  }

  hashCode(str: string): number {
    let hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }


  listKota = [
"ACEH TENGGARA",
"ACEH TIMUR",
"ACEH TENGAH",
"ACEH BARAT",
"ACEH BESAR",
"PIDIE",
"ACEH UTARA",
"SIMEULUE",
"ACEH SINGKIL",
"BIREUEN",
"ACEH BARAT DAYA",
"GAYO LUES",
"ACEH JAYA",
"NAGAN RAYA",
"ACEH TAMIANG",
"BENER MERIAH",
"PIDIE JAYA",
"KOTA BANDA ACEH",
"KOTA SABANG",
"KOTA LHOKSEUMAWE",
"KOTA LANGSA",
"KOTA SUBULUSSALAM",
"TAPANULI TENGAH",
"TAPANULI UTARA",
"TAPANULI SELATAN",
"NIAS",
"LANGKAT",
"KARO",
"DELI SERDANG",
"SIMALUNGUN",
"ASAHAN",
"LABUHANBATU",
"DAIRI",
"TOBA SAMOSIR",
"MANDAILING NATAL",
"NIAS SELATAN",
"PAKPAK BHARAT",
"HUMBANG HASUNDUTAN",
"SAMOSIR",
"SERDANG BEDAGAI",
"BATU BARA",
"PADANG LAWAS UTARA",
"PADANG LAWAS",
"LABUHANBATU SELATAN",
"LABUHANBATU UTARA",
"NIAS UTARA",
"NIAS BARAT",
"KOTA MEDAN",
"KOTA PEMATANG SIANTAR",
"KOTA SIBOLGA",
"KOTA TANJUNG BALAI",
"KOTA BINJAI",
"KOTA TEBING TINGGI",
"KOTA PADANG SIDIMPUAN",
"KOTA GUNUNGSITOLI",
"PESISIR SELATAN",
"SOLOK",
"SIJUNJUNG",
"TANAH DATAR",
"PADANG PARIAMAN",
"AGAM",
"LIMA PULUH KOTA",
"PASAMAN",
"KEPULAUAN MENTAWAI",
"DHARMASRAYA",
"SOLOK SELATAN",
"PASAMAN BARAT",
"KOTA PADANG",
"KOTA SOLOK",
"KOTA SAWAHLUNTO",
"KOTA PADANG PANJANG",
"KOTA BUKITTINGGI",
"KOTA PAYAKUMBUH",
"KOTA PARIAMAN",
"KAMPAR",
"INDRAGIRI HULU",
"BENGKALIS",
"INDRAGIRI HILIR",
"PELALAWAN",
"ROKAN HULU",
"ROKAN HILIR",
"SIAK",
"KUANTAN SINGINGI",
"KEPULAUAN MERANTI",
"KOTA PEKANBARU",
"KOTA DUMAI",
"KERINCI",
"MERANGIN",
"SAROLANGUN",
"BATANG HARI",
"MUARO JAMBI",
"TANJUNG JABUNG BARAT",
"TANJUNG JABUNG TIMUR",
"BUNGO",
"TEBO",
"KOTA JAMBI",
"KOTA SUNGAI PENUH",
"OGAN KOMERING ULU",
"OGAN KOMERING ILIR",
"MUARA ENIM",
"LAHAT",
"MUSI RAWAS",
"MUSI BANYUASIN",
"BANYUASIN",
"OGAN KOMERING ULU TIMUR",
"OGAN KOMERING ULU SELATAN",
"OGAN ILIR",
"EMPAT LAWANG",
"KOTA PALEMBANG",
"KOTA PAGAR ALAM",
"KOTA LUBUK LINGGAU",
"KOTA PRABUMULIH",
"BENGKULU SELATAN",
"REJANG LEBONG",
"BENGKULU UTARA",
"KAUR",
"SELUMA",
"MUKO MUKO",
"LEBONG",
"KEPAHIANG",
"BENGKULU TENGAH",
"KOTA BENGKULU",
"LAMPUNG SELATAN",
"LAMPUNG TENGAH",
"LAMPUNG UTARA",
"LAMPUNG BARAT",
"TULANG BAWANG",
"TANGGAMUS",
"LAMPUNG TIMUR",
"WAY KANAN",
"PESAWARAN",
"PRINGSEWU",
"MESUJI",
"TULANG BAWANG BARAT",
"KOTA BANDAR LAMPUNG",
"KOTA METRO",
"BANGKA",
"BELITUNG",
"BANGKA SELATAN",
"BANGKA TENGAH",
"BANGKA BARAT",
"BELITUNG TIMUR",
"KOTA PANGKAL PINANG",
"BINTAN",
"KARIMUN",
"NATUNA",
"LINGGA",
"KEPULAUAN ANAMBAS",
"KOTA BATAM",
"KOTA TANJUNG PINANG",
"KEPULAUAN SERIBU",
"BOGOR",
"SUKABUMI",
"CIANJUR",
"BANDUNG",
"GARUT",
"TASIKMALAYA",
"CIAMIS",
"KUNINGAN",
"CIREBON",
"MAJALENGKA",
"SUMEDANG",
"INDRAMAYU",
"SUBANG",
"PURWAKARTA",
"KARAWANG",
"BEKASI",
"BANDUNG BARAT",
"KOTA BOGOR",
"KOTA SUKABUMI",
"KOTA BANDUNG",
"KOTA CIREBON",
"KOTA BEKASI",
"KOTA DEPOK",
"KOTA CIMAHI",
"KOTA TASIKMALAYA",
"KOTA BANJAR",
"CILACAP",
"BANYUMAS",
"PURBALINGGA",
"BANJARNEGARA",
"KEBUMEN",
"PURWOREJO",
"WONOSOBO",
"MAGELANG",
"BOYOLALI",
"KLATEN",
"SUKOHARJO",
"WONOGIRI",
"KARANGANYAR",
"SRAGEN",
"GROBOGAN",
"BLORA",
"REMBANG",
"PATI",
"KUDUS",
"JEPARA",
"DEMAK",
"SEMARANG",
"TEMANGGUNG",
"KENDAL",
"BATANG",
"PEKALONGAN",
"PEMALANG",
"TEGAL",
"BREBES",
"KOTA MAGELANG",
"KOTA SURAKARTA",
"KOTA SALATIGA",
"KOTA SEMARANG",
"KOTA PEKALONGAN",
"KOTA TEGAL",
"KULONPROGO",
"BANTUL",
"GUNUNG KIDUL",
"SLEMAN",
"KOTA YOGYAKARTA",
"PACITAN",
"PONOROGO",
"TRENGGALEK",
"TULUNGAGUNG",
"BLITAR",
"KEDIRI",
"MALANG",
"LUMAJANG",
"JEMBER",
"BANYUWANGI",
"BONDOWOSO",
"SITUBONDO",
"PROBOLINGGO",
"PASURUAN",
"SIDOARJO",
"MOJOKERTO",
"JOMBANG",
"NGANJUK",
"MADIUN",
"MAGETAN",
"NGAWI",
"BOJONEGORO",
"TUBAN",
"LAMONGAN",
"GRESIK",
"BANGKALAN",
"SAMPANG",
"PAMEKASAN",
"SUMENEP",
"KOTA KEDIRI",
"KOTA BLITAR",
"KOTA MALANG",
"KOTA PROBOLINGGO",
"KOTA PASURUAN",
"KOTA MOJOKERTO",
"KOTA MADIUN",
"KOTA SURABAYA",
"KOTA BATU",
"PANDEGLANG",
"LEBAK",
"TANGERANG",
"SERANG",
"KOTA TANGERANG",
"KOTA CILEGON",
"KOTA SERANG",
"KOTA TANGERANG SELATAN",
"JEMBRANA",
"TABANAN",
"BADUNG",
"GIANYAR",
"KLUNGKUNG",
"BANGLI",
"KARANG ASEM",
"BULELENG",
"KOTA DENPASAR",
"LOMBOK BARAT",
"LOMBOK TENGAH",
"LOMBOK TIMUR",
"SUMBAWA",
"DOMPU",
"BIMA",
"SUMBAWA BARAT",
"LOMBOK UTARA",
"KOTA MATARAM",
"KOTA BIMA",
"KUPANG",
"TIMOR TENGAH SELATAN",
"TIMOR TENGAH UTARA",
"BELU",
"ALOR",
"FLORES TIMUR",
"SIKKA",
"ENDE",
"NGADA",
"MANGGARAI",
"SUMBA TIMUR",
"SUMBA BARAT",
"LEMBATA",
"ROTE NDAO",
"MANGGARAI BARAT",
"NAGEKEO",
"SUMBA TENGAH",
"SUMBA BARAT DAYA",
"MANGGARAI TIMUR",
"SABU RAIJUA",
"KOTA KUPANG",
"SAMBAS",
"PONTIANAK",
"SANGGAU",
"KETAPANG",
"SINTANG",
"KAPUAS HULU",
"BENGKAYANG",
"LANDAK",
"SEKADAU",
"MELAWI",
"KAYONG UTARA",
"KUBU RAYA",
"KOTA PONTIANAK",
"KOTA SINGKAWANG",
"KOTAWARINGIN BARAT",
"KOTAWARINGIN TIMUR",
"KAPUAS",
"BARITO SELATAN",
"BARITO UTARA",
"KATINGAN",
"SERUYAN",
"SUKAMARA",
"LAMANDAU",
"GUNUNG MAS",
"PULANG PISAU",
"MURUNG RAYA",
"BARITO TIMUR",
"KOTA PALANGKARAYA",
"TANAH LAUT",
"KOTABARU",
"BANJAR",
"BARITO KUALA",
"TAPIN",
"HULU SUNGAI SELATAN",
"HULU SUNGAI TENGAH",
"HULU SUNGAI UTARA",
"TABALONG",
"TANAH BUMBU",
"BALANGAN",
"KOTA BANJARMASIN",
"KOTA BANJAR BARU",
"PASER",
"KUTAI KERTANEGARA",
"BERAU",
"BULUNGAN",
"NUNUKAN",
"MALINAU",
"KUTAI BARAT",
"KUTAI TIMUR",
"PENAJAM PASER UTARA",
"TANA TIDUNG",
"KOTA BALIKPAPAN",
"KOTA SAMARINDA",
"KOTA TARAKAN",
"KOTA BONTANG",
"BOLAANG MONGONDOW",
"MINAHASA",
"KEPULAUAN SANGIHE",
"KEPULAUAN TALAUD",
"MINAHASA SELATAN",
"MINAHASA UTARA",
"MINAHASA TENGGARA",
"BOLAANG MONGONDOW UTARA",
"KEP. SIAU TAGULANDANG BIARO",
"BOLAANG MONGONDOW TIMUR",
"BOLAANG MONGONDOW SELATAN",
"KOTA MANADO",
"KOTA BITUNG",
"KOTA TOMOHON",
"KOTA KOTAMOBAGU",
"BANGGAI",
"POSO",
"DONGGALA",
"TOLI TOLI",
"BUOL",
"MOROWALI",
"BANGGAI KEPULAUAN",
"PARIGI MOUTONG",
"TOJO UNA UNA",
"SIGI",
"KOTA PALU",
"KEPULAUAN SELAYAR",
"BULUKUMBA",
"BANTAENG",
"JENEPONTO",
"TAKALAR",
"GOWA",
"SINJAI",
"BONE",
"MAROS",
"PANGKAJENE KEPULAUAN",
"BARRU",
"SOPPENG",
"WAJO",
"SIDENRENG RAPANG",
"PINRANG",
"ENREKANG",
"LUWU",
"TANA TORAJA",
"LUWU UTARA",
"LUWU TIMUR",
"TORAJA UTARA",
"KOTA MAKASSAR",
"KOTA PARE PARE",
"KOTA PALOPO",
"KOLAKA",
"KONAWE",
"MUNA",
"BUTON",
"KONAWE SELATAN",
"BOMBANA",
"WAKATOBI",
"KOLAKA UTARA",
"KONAWE UTARA",
"BUTON UTARA",
"KOTA KENDARI",
"KOTA BAU BAU",
"GORONTALO",
"BOALEMO",
"BONE BOLANGO",
"PAHUWATO",
"GORONTALO UTARA",
"KOTA GORONTALO",
"MAMUJU UTARA",
"MAMUJU",
"MAMASA",
"POLEWALI MANDAR",
"MAJENE",
"MALUKU TENGAH",
"MALUKU TENGGARA",
"MALUKU TENGGARA BARAT",
"BURU",
"SERAM BAGIAN TIMUR",
"SERAM BAGIAN BARAT",
"KEPULAUAN ARU",
"MALUKU BARAT DAYA",
"BURU SELATAN",
"KOTA AMBON",
"KOTA TUAL",
"HALMAHERA BARAT",
"HALMAHERA TENGAH",
"HALMAHERA UTARA",
"HALMAHERA SELATAN",
"KEPULAUAN SULA",
"HALMAHERA TIMUR",
"PULAU MOROTAI",
"KOTA TERNATE",
"KOTA TIDORE KEPULAUAN",
"MERAUKE",
"JAYAWIJAYA",
"JAYAPURA",
"NABIRE",
"KEPULAUAN YAPEN",
"BIAK NUMFOR",
"PUNCAK JAYA",
"PANIAI",
"MIMIKA",
"SARMI",
"KEEROM",
"PEGUNUNGAN BINTANG",
"YAHUKIMO",
"TOLIKARA",
"WAROPEN",
"BOVEN DIGUL",
"MAPPI",
"ASMAT",
"SUPIORI",
"MAMBERAMO RAYA",
"MAMBERAMO TENGAH",
"YALIMO",
"LANNY JAYA",
"NDUGA",
"PUNCAK",
"DOGIYAI",
"INTAN JAYA",
"DEIYAI",
"KOTA JAYAPURA",
"SORONG",
"MANOKWARI",
"FAK FAK",
"SORONG SELATAN",
"RAJA AMPAT",
"TELUK BINTUNI",
"TELUK WONDAMA",
"KAIMANA",
"TAMBRAUW",
"MAYBRAT",
"KOTA SORONG",
"JAKARTA BARAT",
"JAKARTA PUSAT",
"JAKARTA SELATAN",
"JAKARTA TIMUR",
"JAKARTA UTARA",
"ACEH SELATAN",
"LUAR NEGERI"
  ]

  listProvinsi= [
    
"ACEH",
"SUMATERA UTARA",
"SUMATERA BARAT",
"RIAU",
"JAMBI",
"SUMATERA SELATAN",
"BENGKULU",
"LAMPUNG",
"KEPULAUAN BANGKA BELITUNG",
"KEPULAUAN RIAU",
"DKI JAKARTA",
"JAWA BARAT",
"JAWA TENGAH",
"DAERAH ISTIMEWA YOGYAKARTA",
"JAWA TIMUR",
"BANTEN",
"BALI",
"NUSA TENGGARA BARAT",
"NUSA TENGGARA TIMUR",
"KALIMANTAN BARAT",
"KALIMANTAN TENGAH",
"KALIMANTAN SELATAN",
"KALIMANTAN TIMUR",
"SULAWESI UTARA",
"SULAWESI TENGAH",
"SULAWESI SELATAN",
"SULAWESI TENGGARA",
"GORONTALO",
"SULAWESI BARAT",
"MALUKU",
"MALUKU UTARA",
"PAPUA",
"PAPUA BARAT",
"KALIMANTAN UTARA",
"LUAR NEGERI"
  ]

}
