import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EFpkService {
  eFpkBaseUrl = environment.baseBackendUrl + '/efpk/agent';

  getEFpkDataUrl = this.eFpkBaseUrl + '/all';
  postEFpkDataUrl = this.eFpkBaseUrl + '/create';
  postEFpkDataUrlNew = this.eFpkBaseUrl + '/create-new';
  submitDraftEFpkDataUrl = this.eFpkBaseUrl + '/save-draft';
  editEFpkDataUrl = environment.baseBackendUrl + '/efpk/do';
  private aajiUploadUrl = this.eFpkBaseUrl + '/aaji-upload';
  introducerUrl = this.eFpkBaseUrl + '/introducer';
  branchUrl = this.eFpkBaseUrl + '/branch';
  private channelOrgUrl = this.eFpkBaseUrl + '/channel-organization';

  constructor(private http: HttpClient) { }

  getEFpkData() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getEFpkDataUrl, httpOptions);
  }

  getEFpkDataFilter(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.getEFpkDataUrl, request, httpOptions);
  }

  getEFpkDataById(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.eFpkBaseUrl + '/' + id + '/findAlamat', httpOptions);
  }

  getEFpkDataByIdOld(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.eFpkBaseUrl + '/' + id + '/find', httpOptions);
  }

  saveEFpkGenerateDate(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.eFpkBaseUrl + '/' + id + '/date', httpOptions);
  }

  submitDraftData(efpkData: any, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.submitDraftEFpkDataUrl + '/' + id, efpkData, httpOptions);
  }

  createEFpkData(efpkData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.postEFpkDataUrl, efpkData, httpOptions);
  }

  approveEFpkData(id: number, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.eFpkBaseUrl + '/' + id + '/approve', data, httpOptions);
  }

  editDOEFpkData(id: number, note: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.editEFpkDataUrl + '/' + id + '/note', note, httpOptions);
  }

  updateDocumentEFpkById(id: number, request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.put(this.eFpkBaseUrl + '/' + id + '/update-document', request, httpOptions);
  }

  convertFileAssets(dirURL) {
    return this.http.get(dirURL, { responseType: 'blob' });
  }

  aajiUpload(formData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.aajiUploadUrl, formData, httpOptions);
  }

  getIntroducer(locationId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.introducerUrl + '/' + locationId, httpOptions);
  }

  getLeaderIntroducer(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.introducerUrl, request, httpOptions);
  }

  getBranch() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.branchUrl, httpOptions);
  }

  getBranchByBranchId(branchId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(`${this.branchUrl}/${branchId}`, httpOptions);
  }

  getChannelOrgByAgentCode(agentCode: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(`${this.channelOrgUrl}/${agentCode}`, httpOptions);
  }
}
