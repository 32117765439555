import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app-service/app.service';
import { ElearningService } from 'src/app/services/elearning-service/elearning.service';
import { LoginService } from 'src/app/services/login/login.service';
import { UtilService } from 'src/app/services/util-service/util.service';
import { ModalLoginLupaPasswordComponent } from '../modal-login-lupa-password/modal-login-lupa-password.component';
import { ModalWarningComponent } from '../modal-warning/modal-warning.component';
import { PpajInquiryServiceService } from 'src/app/services/ppaj-inquiry-service/ppaj-inquiry-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;

  showSpinner = false;

  userForm: FormGroup;
  formErrors = {
    username: '',
    password: ''
  };
  validationMessages = {
    username: {
      required: 'Please enter your agent code',
    },
    password: {
      required: 'please enter your password',
    }
  };
  isLogin = false;

  //hc login
  // dataLogin = {
  //     'username': 'Chubb',
  //     'password':'portal12345'
  // }
  dataLogin = {
    'username': '00060',
    'password': 'password'
  }

  loginType = [
    {
      'id': 'DIST',
      'value': 'Sales Force'
    },
    {
      'id': 'STAFF',
      'value': 'Staff'
    }
  ]

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private appService: AppService,
    private util: UtilService,
    private loginService: LoginService,
    public dialog: MatDialog,
    public dialogLupaPass: MatDialog,
    private elearningService: ElearningService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      loginType: ['DIST', Validators.required],
    });

    this.userForm.get('username').valueChanges.subscribe(value => {
      if (/[A-Za-z]/.test(value)) {
        this.userForm.get('loginType').setValue('STAFF');
      }
    });
  }

  get f() { return this.userForm.controls; }



  login() {
    // this.checkIsMatched();
    if (this.userForm.invalid) {
      this.util.validateAllFormFields(this.userForm);
    } else {
      this.showSpinner = true;
      let tmpReq = {
        'data': this.appService.getDataEncryption(this.userForm.getRawValue())
      }

      this.loginService.processLogin(tmpReq).subscribe((data: any) => {
        if (data['status'] == 'success') {
          localStorage.removeItem('token');
          localStorage.removeItem('IS_DO_COMP');
          localStorage.setItem('token', data['token']);
          this.appService.getUserData({}).subscribe((res) => {
            const data = res['data'];
            if (res['status'] == 'success') {
              if (data['loginAs'] == "AGENCY") {
                if (data['m_user']) {
                  localStorage.removeItem('privileges');
                  let privileges = [];
                  data['m_user']['role']['privileges'].forEach(element => {
                    privileges.push(element['name']);
                  });
                  localStorage.setItem('privileges', JSON.stringify(privileges));
                  this.router.navigate(['/auth/dashboard']);
                  localStorage.removeItem('loginCategory');
                  localStorage.setItem('loginCategory', data['m_user']['loginCategory']);
                  localStorage.setItem('IS_DO_COMP', data['m_user']['role']['name'] == 'DO Compensation' ? '1' : '0');

                  if (data['m_user']['loginCategory'] == '01') {
                    this.elearningService.checkUnfinishedTraining(this.f.username.value).subscribe((res_tr: any) => {

                      if (res_tr.status == 'success') {
                        const dataTraining = res_tr.data
                        if (dataTraining.length > 0) {
                          setTimeout(() => {
                            this.callTrainingAlert(dataTraining)
                          }, 1000)
                        }
                      }
                    })
                  }
                }
              } else if (data['loginAs'] == "TM") {
                if (data['tm_user']) {
                  localStorage.removeItem('privileges');
                  let privileges = [];
                  if (data['tm_user']['role']) {
                    data['tm_user']['role']['privileges'].forEach(element => {
                      privileges.push(element['name']);
                    });
                  }
                  localStorage.setItem('privileges', JSON.stringify(privileges));
                  this.router.navigate(['/auth/dashboard']);
                  localStorage.removeItem('loginCategory');
                  localStorage.setItem('loginCategory', data['tm_user']['loginCategory']);
                }
              } else if (data['loginAs'] == null) {
                localStorage.removeItem('token');
                let errorMessage = 'User not registered! Please register it first!s';
                this.callWarningModal(errorMessage);
                this.showSpinner = false;
              }
            } else {
              this.callWarningModal('Bad Login!');
              this.showSpinner = false;
            }
          });
        } else {
          // let errorMessage = data['message'];
          if (this.userForm.get('loginType').value == 'STAFF') {
            if (data.error) {
              const clientErrException: any = JSON.parse(data.error);
              this.callWarningModal(clientErrException.message);
              this.showSpinner = false;
            } else {
              let errorMessage = 'Username atau password salah';
              this.callWarningModal(errorMessage);
              this.showSpinner = false;
            }
          } else {
            let errorMessage = '';
            if (data['exception'].includes("Silahkan Mengecek Email Anda untuk Verifikasi Link")) {
              errorMessage = 'Silahkan Mengecek Email Anda untuk Verifikasi Link Terlebih Dahulu';
            } else if (data['exception'].includes("Password yang Anda Masukan Salah")) {
              errorMessage = 'Password yang Anda Masukan Salah';
            } else if (data['exception'].includes("User tidak ditemukan")) {
              errorMessage = 'User tidak ditemukan';
            } else if (data['exception'].includes(" License is expired")) {
              errorMessage = 'Agent License is expired. Please contact DO';
            } else {
            let errorMessageArr = data['exception'].split(':');
            let errorCode = errorMessageArr[1]
            let errorMsg = errorMessageArr[2] === " [{\"timestamp\"" ? 'Username atau password salah' : errorMessageArr[2];
            errorMessage = errorCode + "-" + errorMsg;
            }
            this.callWarningModal(errorMessage);
            this.showSpinner = false;
          }
        }
      }, err => {

        // let errorMessage = 'Username isn\'t registered. Please try again';
        let errorMessage = 'Username atau password salah';
        this.callWarningModal(errorMessage);
        this.showSpinner = false;
      });
    }
  }

  callWarningModal(msg: string) {
    const dialogRef = this.dialog.open(ModalWarningComponent, { data: { msg } });
    dialogRef.afterClosed().subscribe(res => {
    });
  }

  checkIsMatched() {
    let mapFormCtrl = ['username', 'password'];
    for (let i = 0; i < mapFormCtrl.length; i++) {
      if (this.userForm.controls[mapFormCtrl[i]].value != this.dataLogin[mapFormCtrl[i]]) {
        this.userForm.get(mapFormCtrl[i]).setErrors({
          'isIncorrect': true
        });
      } else {
        this.userForm.get(mapFormCtrl[i]).setErrors(null);
      }
    }
  }

  hideEye() {
    if (this.hide) {
      this.hide = false;
    }
    else {
      this.hide = true;
    }
  }


  callTrainingAlert(trainingList: []) {
    const dialogRef = this.dialog.open(
      ModalWarningComponent,
      {
        data: {
          trainings: trainingList
        }
      })
    dialogRef.afterClosed().subscribe((res: any) => { })
  }

  lupaPasswordModal() {
    console.log("testing")
    this.dialogLupaPass.open(ModalLoginLupaPasswordComponent, {
      width: '250px',
    });
  }
}


